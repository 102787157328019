<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { RouterLinkBack, VButtonInvisible, VLoadingSection } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import CapitalCallForm from '../components/capital-call-form.vue'
import { useCapitalCallStore } from '../stores/capital-call-store'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const capitalCallStore = useCapitalCallStore()
const capital_call = ref(null)

const submit = async (payload) => {
  await capitalCallStore.addCapitalCall(payload)
  router.push({ name: 'investing.calls' })
}

onMounted(async () => {
  await capitalCallStore.fetchCapitalCall(route.params.entity_type, route.params.entity_id)
  capital_call.value = capitalCallStore.items.get('undefined:null') // this is the cid if id is null
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'investing.calls' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">New Capital Call</div>
    <CapitalCallForm :capital_call="capital_call" :onSave="submit" v-if="!skeleton" />
    <VLoadingSection
      class="mt-40"
      title="Preparing Your Form..."
      description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs
      smoothly."
      v-else
    />
  </TheLayout>
</template>
