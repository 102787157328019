<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { capitalize, get } from 'lodash'
import { useI18n } from 'vue-i18n'
import { Column, calculate_aggregate } from '@/modules/shared/utils/v-table'
import { useDistributionData } from '../composables/use-distribution-data'
import { Money, initialMoney } from '@/modules/shared/utils/money'
import { useDistributionStore } from '../stores/distribution-store'
import { useModal } from '@/modules/shared/composables/use-modal'
import DistributionsBarChart from './distributions-bar-chart.vue'
import { VBadge, VModal, VTextField, VButton, VIcon, VSection, VTable, VLink } from '@/modules/shared/components'
import { format } from 'date-fns'
import { rails_url } from '@/modules/shared/utils/rails'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    distributions: any[]
    currency?: string
    chartData: any
    disabledColumns?: string[]
    investable_id: string
    investable_type: string
    isAdmin: boolean
    portfolio_distributions: any[]
    skeleton: boolean
  }>(),
  {
    disabledColumns: [],
  },
)

const distributionStore = useDistributionStore()

const current_distributions = computed(() => props.distributions)
const current_portfolio_distributions = computed(() => props.portfolio_distributions)
const isAdmin = computed(() => props.isAdmin)

const selectedDistributionType = ref('investors')
const selectDistributionType = (type: string) => {
  selectedDistributionType.value = type
}

const removeDistribution = async (item) => {
  if (get(item, 'type') === 'transfer distribution') return
  if (!window.confirm('Are you sure?')) return
  await distributionStore.removeDistribution(item.id, props.investable_type, props.investable_id)
}

const initialState = {
  disbursement_id: null,
  paid_date: format(new Date(), 'yyyy-MM-dd'),
}

const slideInFormModal = useModal()
const distribution = reactive({ ...initialState })

const open_pay_distribution = (item) => {
  distribution.disbursement_id = item.id
  slideInFormModal.open()
}

const pay_distribution = async () => {
  await distributionStore.markPaymentAsPaid(
    { ...distribution, id: distribution.disbursement_id },
    props.investable_type,
    props.investable_id,
  )

  slideInFormModal.close()

  // gives the modal time to close
  setTimeout(() => {
    Object.keys(distribution).forEach((key) => {
      distribution[key] = initialState[key]
    })
  }, 250)
}

const aggregate_column = (key, type, aggregate): Column => {
  return {
    key,
    name: null,
    type,
    align: 'right',
    is_visible: true,
    aggregate,
  }
}

const generateAllocation = (allocation, keys: string[]) => {
  let data = {
    ...allocation,
    share_price: ['Cash', 'Dividend'].includes(allocation.type)
      ? { amount: 0, common_amount: 0, currency: 'USD', common_currency: 'USD', date: null }
      : allocation.share_price,
  }
  keys.forEach((key) => {
    const aggregate = calculate_aggregate(allocation.investors, aggregate_column(key, 'currency', 'sum')) as Money

    data[key] = {
      amount: aggregate.amount,
      common_amount: aggregate.common_amount,
      currency: aggregate.currency,
      common_currency: aggregate.common_currency,
      date: null,
    }
  })

  return data
}

const equity_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Equity')
    .map((allocation) => generateAllocation(allocation, ['total_received'])),
)

const convertible_note_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Convertible Note')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const safe_note_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Safe Note')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const loan_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) => allocation.term_sheet?.investment_type === 'Loan')
    .map((allocation) => generateAllocation(allocation, ['total_received', 'total_principal', 'total_interest'])),
)

const revenue_share_allocations = computed(() =>
  current_portfolio_distributions.value
    .filter((allocation) =>
      !allocation.term_sheet
        ? allocation.related_to === 'Revenue Share' || allocation.type === 'Revenue'
        : allocation.term_sheet.investment_type === 'Revenue Share',
    )
    .map((allocation) => generateAllocation(allocation, ['total_received'])),
)

const portfolio_distributions_data = computed((): { label: string; columns: Column[]; items: any[] }[] => [
  {
    label: capitalize(t('shared.equity')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'share_price',
        name: capitalize(t('shared.share price')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'shares_sold',
        name: capitalize(t('shared.shares sold')),
        type: 'number',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: equity_allocations.value,
  },
  {
    label: capitalize(t('shared.convertible note')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: convertible_note_allocations.value,
  },
  {
    label: capitalize(t('shared.safe note')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: safe_note_allocations.value,
  },
  {
    label: capitalize(t('shared.loan')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_principal',
        name: capitalize(t('shared.principal')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_interest',
        name: capitalize(t('shared.interest')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.total amount')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: loan_allocations.value,
  },
  {
    label: capitalize(t('shared.revenue share')),
    columns: [
      {
        key: 'name',
        name: capitalize(t('shared.term sheet')),
        type: 'string',
        align: 'left',
        is_visible: true,
      },
      {
        key: 'date',
        name: capitalize(t('shared.date')),
        sorted: true,
        type: 'date',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'total_received',
        name: capitalize(t('shared.payment')),
        type: 'currency',
        align: 'right',
        is_visible: true,
      },
      {
        key: 'actions',
        name: '',
        type: 'actions',
        align: 'right',
        is_visible: true,
      },
    ],
    items: revenue_share_allocations.value,
  },
])
</script>

<template>
  <div class="-mt-7 mb-5 flex items-center justify-between">
    <div class="flex items-center space-x-1.5" v-if="isAdmin">
      <VButton :active="selectedDistributionType === 'investors'" @click="selectDistributionType('investors')">
        <span>Sent to investors</span>
      </VButton>
      <VButton :active="selectedDistributionType === 'portfolio'" @click="selectDistributionType('portfolio')">
        <span>Received from portfolio</span>
      </VButton>
    </div>
    <div class="flex items-center space-x-1.5">
      <slot name="actions"></slot>
    </div>
  </div>

  <div v-show="selectedDistributionType === 'investors'">
    <VSection>
      <DistributionsBarChart
        :currency="currency"
        :hurdle="chartData.hurdle"
        :distribution_distributed="chartData.distribution_distributed"
        :distribution_distributed_capital="chartData.distribution_distributed_capital"
        :distribution_distributed_profit="chartData.distribution_distributed_profit"
        :distribution_distributed_carried_interest="chartData.distributed_carried_interest"
        :distribution_distributed_other_fees="chartData.distributed_other_fees"
        :distribution_pending="chartData.distribution_pending"
        :distribution_pending_capital="chartData.distribution_pending_capital"
        :distribution_pending_profit="chartData.distribution_pending_profit"
        :distribution_pending_carried_interest="chartData.distribution_pending_carried_interest"
        :distribution_pending_other_fees="chartData.distribution_pending_other_fees"
        :distribution_remaining="chartData.distribution_remaining"
        :skeleton="skeleton"
      />
    </VSection>
    <VTable
      :columns="[
        {
          key: 'investor.name',
          name: capitalize(t('shared.investor')),
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'entity.name',
          name: capitalize(t('shared.entity')),
          type: 'string',
          align: 'left',
          is_visible: !disabledColumns.some((key) => ['entity.name'].includes(key)),
        },
        {
          key: 'date',
          name: capitalize(t('shared.due date')),
          sorted: true,
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'type',
          name: capitalize(t('shared.type')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'company.name',
          name: capitalize(t('shared.company')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'capital',
          name: capitalize(t('shared.capital')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'management_fee',
          name: capitalize(t('shared.carried interest')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'other_fee',
          name: capitalize(t('shared.other fee')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'total',
          name: capitalize(t('shared.distributed')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'payment_method',
          name: capitalize(t('shared.payment method')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'payment_status',
          name: capitalize(t('shared.payment status')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'investor.disbursement_method_confirmed_at',
          name: capitalize(t('shared.payment method confirmed at')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_method confirmed by',
          name: capitalize(t('shared.payment method confirmed by')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_bank_name',
          name: capitalize(t('shared.bank name')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_bank_address',
          name: capitalize(t('shared.bank address')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_bank_routing_number',
          name: capitalize(t('shared.bank routing number')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_bank_account_number',
          name: capitalize(t('shared.bank account number')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_bank_swift_code',
          name: capitalize(t('shared.bank swift code')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_account_name',
          name: capitalize(t('shared.account name')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_wire_for_further_credit_to',
          name: capitalize(t('shared.for further credit to')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_check_receiver_name',
          name: capitalize(t('shared.check receiver name')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.disbursement_other_details',
          name: capitalize(t('shared.other payment method details')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="current_distributions"
      :name="`${investable_type}-${investable_id}-transactions`"
      :skeleton="skeleton"
      :slots="['actions', 'company.name', 'entity.name', 'investor.name', 'payment_status', 'type']"
    >
      <template #investor.name="{ item }">
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="
            get(item, 'investor.ruby_type') === 'fundingentity'
              ? { name: 'investing.other-entity.overview', params: { other_entity_id: get(item, 'investor.id') } }
              : { name: 'investing.individual-overview', params: { individual_id: get(item, 'investor.id') } }
          "
        >
          {{ get(item, 'investor.name') }}
        </RouterLink>
      </template>
      <template #entity.name="{ item }">
        <VLink
          class="hyperlink"
          :to="{
            name: 'investing.entity-overview',
            params: { entity_type: get(item, 'entity.entity_type'), entity_id: get(item, 'entity.id') },
          }"
        >
          {{ get(item, 'entity.name') }}
        </VLink>
      </template>
      <template #company.name="{ item }">
        <a class="hyperlink" :href="`${rails_url()}/companies/${get(item, 'company.id')}}`">
          {{ get(item, 'company.name') }}
        </a>
      </template>
      <template #type="{ item }">
        <VBadge class="inline-flex items-center space-x-1" color="v-green" size="xxs">
          <VIcon name="arrow_narrow_left" class="inline-block h-3 w-3" />
          <span>
            {{ get(item, 'type') }}
          </span>
        </VBadge>
      </template>
      <template #payment_status="{ item }">
        {{ `${get(item, 'payment_status')} ${get(item, 'payment_status_marked_at') || ''}` }}
      </template>
      <template #actions="{ item }" v-if="isAdmin">
        <div class="flex gap-3">
          <RouterLink
            :to="{
              name: 'investing.disbursement.edit',
              params: {
                entity_type: investable_type,
                entity_id: investable_id,
                disbursement_id: get(item, 'transaction.id'),
              },
            }"
            v-if="get(item, 'type') !== 'transfer distribution'"
          >
            <VButton size="xs">
              {{ capitalize(t('shared.edit')) }}
            </VButton>
          </RouterLink>
          <template v-if="get(item, 'type') !== 'transfer distribution'">
            <VButton
              :click="() => distributionStore.markPaymentAsUnpaid(item.id, props.investable_type, props.investable_id)"
              size="xs"
              v-if="item.payment_status === 'paid'"
            >
              {{ capitalize(t('shared.mark as pending')) }}
            </VButton>
            <VButton :click="() => open_pay_distribution(item)" size="xs" v-else>
              {{ capitalize(t('shared.mark as paid')) }}
            </VButton>
          </template>
          <VButton
            :click="() => removeDistribution(item)"
            size="xs"
            v-if="get(item, 'type') !== 'transfer distribution'"
          >
            {{ capitalize(t('shared.remove')) }}
          </VButton>
        </div>
      </template>
    </VTable>
  </div>
  <div v-show="selectedDistributionType === 'portfolio'" v-if="isAdmin">
    <template v-for="portfolio_distribution in portfolio_distributions_data" :key="portfolio_distribution.label">
      <VSection v-if="portfolio_distribution.items.length > 0" :label="portfolio_distribution.label">
        <VTable
          :columns="portfolio_distribution.columns"
          :items="portfolio_distribution.items"
          :name="`${investable_type}-${investable_id}-portfolio_disbursements-equity`"
          :skeleton="skeleton"
          :slots="['actions', 'name']"
        >
          <template #name="{ item }">
            <a
              :href="`${rails_url()}/companies/${get(item, 'asset.id')}/disbursements`"
              v-if="isAdmin"
              class="hyperlink"
            >
              {{ get(item, 'asset.name') }} - {{ get(item, 'related_to') || get(item, 'term_sheet.name') }} -
              {{ get(item, 'type') }}
            </a>
            <div v-else>
              {{ get(item, 'asset.name') }} - {{ get(item, 'related_to') || get(item, 'term_sheet.name') }} -
              {{ get(item, 'type') }}
            </div>
          </template>
          <template #actions="{ item }" v-if="isAdmin">
            <div class="flex justify-end gap-3">
              <RouterLink
                :to="{
                  name: 'investing.disbursement.new',
                  query: { portfolio_disbursement_allocation_id: get(item, 'id') },
                }"
                v-if="!get(item, 'investor_set_disbursement.id')"
              >
                <VButton size="xs">
                  {{ capitalize(t('shared.distribute')) }}
                </VButton>
              </RouterLink>
              <RouterLink
                :to="{
                  name: 'investing.disbursement.edit',
                  params: { disbursement_id: get(item, 'investor_set_disbursement.id') },
                }"
                v-if="get(item, 'investor_set_disbursement.id')"
              >
                <VButton size="xs">
                  {{ capitalize(t('shared.view details')) }}
                </VButton>
              </RouterLink>
            </div>
          </template>
        </VTable>
      </VSection>
    </template>
  </div>

  <VModal :modalStore="slideInFormModal">
    <template #main>
      <VTextField
        v-model="distribution.paid_date"
        :label="capitalize(t('shared.date'))"
        property="date"
        type="date_without_timezone"
      />
    </template>
    <template #footer>
      <VButton :click="pay_distribution" variant="primary" size="lg" class="w-full">Mark as paid</VButton>
    </template>
  </VModal>
</template>
