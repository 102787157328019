import { Money, toNumber, initialMoney, difference, times, minus, sum } from '@/modules/shared/utils/money'

type Transfer = {
  id: number
  capital: Money
  commitment: {
    id: number
    capital: Money
    carried_interest_percentage: number
    investor: {
      name: string
    }
    hurdle: Money
    management_fee_percentage: number
    ownership_without_fees_by_called_amount: number
    preferred_return_percentage: number
  }
  fee_amount: Money
  other_fees: Money
}
type TransformedTransfer = {
  id: number
  investor_set_commitment_id: number
  is_active: boolean
  name: string
  capital: Money
  profit: Money
  carried_interest: Money
  other_fees: Money
  total_distributed: Money
  hurdle_remaining: Money
  ownership_percentage: number
  commitment: Money
  commitment_invested: Money
  carried_interest_percentage: number
  preferred_return_percentage: number
  total_capital: Money
  pre_hurdle: Money
}
type Macros = {
  total_capital: Money
  carried_interest: number
  other_fees: Money
}

////////////////////////////////////////
//// Calculations
////////////////////////////////////////
const calculateCapital = (transfer: TransformedTransfer) => {
  const total_capital = transfer.total_capital || initialMoney
  const pre_hurdle = transfer.pre_hurdle || initialMoney

  return toNumber(total_capital) >= toNumber(pre_hurdle) ? pre_hurdle : total_capital
}
const calculateProfit = (transfer: TransformedTransfer) => {
  let amount = null
  const total_capital = transfer.total_capital || initialMoney
  const capital = transfer.capital || initialMoney
  const pre_hurdle = transfer.pre_hurdle || initialMoney

  if (toNumber(total_capital) < toNumber(capital)) {
    amount = minus(capital, pre_hurdle)
  } else {
    amount = minus(total_capital, capital)
  }
  return toNumber(amount) > 0 ? amount : initialMoney
}
const calculateCarriedInterest = (transfer: TransformedTransfer, macros?: Macros) => {
  const profit = transfer.profit || initialMoney
  const carried_interest_percentage = transfer.carried_interest_percentage || 0
  const m_carried_interest = (macros?.carried_interest || 0) / 100
  let carried_interest = initialMoney

  if (!!macros?.carried_interest && parseFloat(macros?.carried_interest) > 0) {
    carried_interest = times(profit, m_carried_interest)
  } else {
    carried_interest = times(profit, carried_interest_percentage)
  }

  return carried_interest
}
const calculateTotalDistributed = (transfer: TransformedTransfer) => {
  const capital = transfer.capital || initialMoney
  const profit = transfer.profit || initialMoney
  const carried_interest = transfer.carried_interest || initialMoney
  const other_fees = transfer.other_fees || initialMoney

  // total capital is being calculated because capital and profit can be manually changed
  const total_capital = sum([capital, profit])
  return difference([total_capital, carried_interest, other_fees])
}
const calculateHurdleRemaining = (transfer: TransformedTransfer) => {
  const capital = transfer.capital || initialMoney
  const pre_hurdle = transfer.pre_hurdle || initialMoney

  const hurdle_remaining = minus(pre_hurdle, capital)
  if (toNumber(hurdle_remaining) <= 0) return initialMoney
  return hurdle_remaining
}
const calculateTransferValues = (transfer: TransformedTransfer, macros?: Macros, input_key?: string) => {
  if (!macros) {
    // this is for the transfers fields
    // we use switch to prevent the calculations of the specific column to affect its previous columns
    switch (input_key) {
      case 'total_capital':
        transfer.capital = calculateCapital(transfer)
        transfer.profit = calculateProfit(transfer)
        transfer.carried_interest = calculateCarriedInterest(transfer)
        break
      case 'capital':
        transfer.profit = calculateProfit(transfer)
        transfer.carried_interest = calculateCarriedInterest(transfer)
        break

      case 'profit':
        transfer.carried_interest = calculateCarriedInterest(transfer, macros)
        break
    }
  } else {
    // this is for the auto calculations
    transfer.capital = calculateCapital(transfer)
    transfer.profit = calculateProfit(transfer)
    transfer.carried_interest = calculateCarriedInterest(transfer, macros)
  }

  transfer.total_distributed = calculateTotalDistributed(transfer)
  transfer.hurdle_remaining = calculateHurdleRemaining(transfer)
  return transfer
}

////////////////////////////////////////
//// Transformations
////////////////////////////////////////
export const transformTransfer = (transfer: Transfer): TransformedTransfer => {
  const t = {
    id: transfer.id,
    investor_set_commitment_id: transfer.commitment.id,

    // table columns
    is_active: true,
    name: transfer.commitment.investor.name,
    total_capital: transfer.capital,
    capital: transfer.return_of_capital,
    profit: transfer.profit,
    carried_interest: transfer.fee_amount,
    other_fees: transfer.other_fees,
    total_distributed: initialMoney,
    hurdle_remaining: initialMoney,

    // fields needed for computation
    pre_hurdle: transfer.commitment.hurdle,
    ownership_percentage: transfer.commitment.ownership_without_fees_by_called_amount,
    commitment: transfer.commitment.capital,
    carried_interest_percentage: transfer.commitment.carried_interest_percentage,
    preferred_return_percentage: transfer.commitment.preferred_return_percentage,
  }
  t.hurdle_remaining = calculateHurdleRemaining(t)
  t.total_distributed = calculateTotalDistributed(t)

  return t
}
export const updateTransfer = (transfer: TransformedTransfer, key?: string) => {
  return calculateTransferValues(transfer, null, key)
}
export const macrosUpdateTransfer = (transfer: TransformedTransfer, macros: Macros, key: string) => {
  if (key !== 'carried_interest') {
    const amount = macros[key] || initialMoney
    transfer[key] = times(amount, transfer.ownership_percentage)
  }
  transfer = calculateTransferValues(transfer, macros, 'carried_interest')
  return transfer
}
