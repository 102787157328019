<script setup lang="ts">
import { VSection, VTextField, VRadio, VTextArea, VButton, VIcon, VButtonInvisible } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { required, not } from '@vuelidate/validators'
import { ref, computed, onMounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { Disbursement } from '../stores/disbursement-store'
import { sum, toNumber, initialMoney } from '@/modules/shared/utils/money'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'
import VCurrencyField from '@/modules/shared/components/v-currency-field.vue'
import { transformTransfer, updateTransfer } from '../utils/use-european-disbursement'
import { macrosUpdateTransfer } from '../utils/use-european-disbursement'
import { da } from 'date-fns/locale'
import { scrollToError } from '@/modules/shared/utils/form'
import VSelect from './v-select.vue'

////////////////////////////////////////

const { n, t } = useExtendedI18n()
const route = useRoute()
////////////////////////////////////////
const props = defineProps<{
  disbursement: Disbursement
  onSave: any
}>()
const is_disbursement_new = computed(() => !props.disbursement.id)
const macros = ref({
  total_capital: props.disbursement.entered_capital_amount,
  carried_interest: props.disbursement.entered_carried_interest_amount,
  other_fees: props.disbursement.entered_other_fees_amount,
})
const toggleAllInvestor = (checked) => {
  if (!is_disbursement_new.value) return
  disbursementForm.value.disbursement_transfers.forEach((transfer) => {
    transfer.is_active = checked
  })
}
const toggleInvestor = (e, transfer) => {
  transfer.is_active = e.target.checked
  if (transfer.is_active) {
    macrosUpdateTransfer(transfer, macros.value, 'total_capital')
    macrosUpdateTransfer(transfer, macros.value, 'carried_interest')
    macrosUpdateTransfer(transfer, macros.value, 'other_fees')
  } else {
    transfer.total_capital = initialMoney
    transfer.capital = initialMoney
    transfer.profit = initialMoney
    transfer.carried_interest = initialMoney
    transfer.other_fees = initialMoney
    updateTransfer(transfer, 'total_capital')
  }
}

// FORM DATA
const transformDisbursement = () => {
  return {
    ...props.disbursement,
    date: props.disbursement.date ? format(new Date(props.disbursement.date), 'yyyy-MM-dd') : null,
    disbursement_transfers: props.disbursement.disbursement_transfers
      .map((transfer: any) => transformTransfer(transfer))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }
}
const rules = {
  prior: { required },
  date: { required },
  type: { required },
}
const disbursementForm = ref(transformDisbursement())
const v$ = useVuelidate(rules, disbursementForm, { $lazy: true })

const resetMacro = (key) => {
  macros.value[key] = null
  macroUpdateTransfers(key)
}
const macroUpdateTransfers = (key: string) => {
  disbursementForm.value.disbursement_transfers
    .filter((transfer) => transfer.is_active)
    .forEach((transfer) => (transfer = macrosUpdateTransfer(transfer, macros.value, key)))
}
const onChangeTransfer = (transfer, key) => {
  transfer = updateTransfer(transfer, key)
}
const aggregateValues = computed(() => {
  const data = {
    total_capital: initialMoney,
    capital: initialMoney,
    profit: initialMoney,
    carried_interest: initialMoney,
    other_fees: initialMoney,
    total_distributed: initialMoney,
    hurdle_remaining: initialMoney,
  }
  disbursementForm.value.disbursement_transfers.forEach((transfer) => {
    if (transfer.is_active) {
      data.total_capital = sum([data.total_capital, transfer.total_capital || initialMoney])
      data.capital = sum([data.capital, transfer.capital || initialMoney])
      data.profit = sum([data.profit, transfer.profit || initialMoney])
      data.carried_interest = sum([data.carried_interest, transfer.carried_interest || initialMoney])
      data.other_fees = sum([data.other_fees, transfer.other_fees || initialMoney])
      data.total_distributed = sum([data.total_distributed, transfer.total_distributed])
      data.hurdle_remaining = sum([data.hurdle_remaining, transfer.hurdle_remaining])
    }
  })

  return data
})

const getCarriedInterest = (transfer) => {
  if (macros.value?.carried_interest) return macros.value.carried_interest / 100
  return transfer.carried_interest_percentage
}

const submit = async () => {
  const valid = await v$.value.$validate()
  if (!valid) {
    scrollToError(v$, Object.keys(rules))
    return
  }
  const payload = {
    id: disbursementForm.value.id,
    investor_set_id: route.params.entity_id,
    portfolio_disbursement_allocation_id: props.disbursement.portfolio_disbursement_allocation_id,
    capital_amount: toNumber(aggregateValues.value.total_capital),
    currency: disbursementForm.value.currency,
    date: disbursementForm.value.date,
    notes: disbursementForm.value.notes,
    prior: `${disbursementForm.value.prior}`,
    fee_percentage: 0,
    fee_percentage_of_commitment: 'false',
    other_fee_amount: toNumber(aggregateValues.value.other_fees),
    include_fees: '1',
    itemize_fee: true,
    entered_capital_amount: macros.value.total_capital ? toNumber(macros.value.total_capital) : null,
    entered_management_fees_amount: macros.value.carried_interest,
    entered_other_fees_amount: macros.value.other_fees ? toNumber(macros.value.other_fees) : null,
    selected_capital_type: null,
    other_fee_type: 'pro-rata',
    type: disbursementForm.value.type,
    disbursement_transfers_attributes: [],
  }
  // get the active transfers
  payload.disbursement_transfers_attributes = disbursementForm.value.disbursement_transfers
    .filter((transfer) => transfer.is_active)
    .map((transfer) => {
      return {
        id: transfer.id,
        investor_set_commitment_id: transfer.investor_set_commitment_id,
        capital_amount: toNumber(transfer.total_capital || initialMoney),
        currency: disbursementForm.value.currency,
        date: null,
        fee_amount: toNumber(transfer.carried_interest || initialMoney),
        other_fee_amount: toNumber(transfer.other_fees || initialMoney),
        prior: false,
        profit_amount: toNumber(transfer.profit || initialMoney),
        return_of_capital_amount: toNumber(transfer.capital || initialMoney),
      }
    })

  await props.onSave(payload)
}

// TABLE CONFIG
const hidden_columns = computed(() => {
  const cols = []

  // if (toNumber(aggregateValues.value.hurdle_remaining) === 0) {
  //   cols.push('hurdle_remaining')
  // } else {
  //   cols.push('profit')
  // }

  return cols
})
const columns = computed(() => {
  return [
    { key: 'total_capital', label: 'Total Capital' },
    { key: 'capital', label: 'Return of Capital' },
    { key: 'profit', label: 'Profit' },
    { key: 'carried_interest', label: 'Carried Interest' },
    { key: 'other_fees', label: 'Other Fees' },
    { key: 'total_distributed', label: 'Total Distributed', disabled: true },
    { key: 'hurdle_remaining', label: 'Capital Hurdle Before Profits', disabled: true },
  ]
})

onMounted(async () => {
  if (is_disbursement_new.value && props.disbursement.portfolio_disbursement_allocation_id) {
    macroUpdateTransfers('total_capital')
    macroUpdateTransfers('carried_interest')
    macroUpdateTransfers('other_fees')
  }
})
</script>

<template>
  <VSection class="flex gap-3" id="general-information">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.general information')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/2</span>
      </h3>
    </div>
    <div class="w-1/2 space-y-7">
      <VRadio
        v-model="disbursementForm.prior"
        :options="[
          {
            label:
              'Send a distribution notification email to each investor and require them to confirm how they would like to receive their funds.',
            value: false,
          },
          { label: 'Do not send an email to investors.', value: true },
        ]"
        optionsDirection="vertical"
        :v$="v$"
        id="disbursement-prior"
        v-if="is_disbursement_new"
      />
      <VTextField
        v-model="disbursementForm.date"
        :label="capitalize(t('shared.distribution date'))"
        type="date"
        property="date"
        :v$="v$"
      />
      <VSelect
        v-model="disbursementForm.type"
        :label="capitalize(t('shared.type'))"
        :options="[
          { label: 'Cash', value: 'cash' },
          { label: 'Asset', value: 'asset' },
          { label: 'Recycled Funds', value: 'recycled' },
        ]"
        property="type"
        :v$="v$"
      />
      <VTextArea
        v-model="disbursementForm.notes"
        :label="capitalize(t('shared.note', 0))"
        property="notes"
        :v$="v$"
        description="This section is included in the body of the email sent. Keep it brief. URLs are hyperlinked."
      />
    </div>
  </VSection>
  <VSection class="space-y-7" id="investor-allocations">
    <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
      {{ capitalize(t('shared.investor allocation', 0)) }}
      <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/2</span>
    </h3>
    <table id="auto-allocate-table" class="w-full">
      <thead>
        <tr>
          <td class="!align-middle font-semibold">Allocate Automatically</td>
          <td>
            <div class="font-semibold">Total Capital</div>
            <div class="mt-3 text-gray-500">
              Enter the total amount of the distribution. Carried interest and Fees are subtracted below.
            </div>
            <br />
          </td>
          <td>
            <div class="font-semibold">Total Carried Interest</div>
            <div class="mt-3 text-gray-500">
              Enter a percentage and the same percentage will be subtracted from all investors based on their capital to
              disburse, overriding any investor specific carried interest.
            </div>
          </td>
          <td>
            <div class="font-semibold">Total Other Fees</div>
            <div class="mt-3 text-gray-500">
              Enter a fixed amount and the fee will be calculated for all investors based on their ownership of the
              fund.
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <VTextField
                v-model="macros.total_capital"
                type="currency"
                :onInput="() => macroUpdateTransfers('total_capital')"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
              />
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton @click="() => resetMacro('total_capital')" size="sm">
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <VTextField
                v-model="macros.carried_interest"
                type="percent"
                :onInput="() => macroUpdateTransfers('carried_interest')"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
                placeholder="--"
              />
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton @click="() => resetMacro('carried_interest')" size="sm">
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <VTextField
                v-model="macros.other_fees"
                type="currency"
                :onInput="() => macroUpdateTransfers('other_fees')"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
              />
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton @click="() => resetMacro('other_fees')" size="sm">
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="relative w-full overflow-x-auto">
      <table id="capital-call-form-table" class="w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th class="pl-3"></th>
            <th class="pl-3">
              <input
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                type="checkbox"
                @change="toggleAllInvestor($event.target['checked'])"
                checked
                :disabled="!is_disbursement_new"
              />
            </th>
            <th class="pl-3 text-left">Investor</th>
            <th class="pl-3 text-right" v-for="column in columns" v-show="!hidden_columns.includes(column.key)">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b-[1px] border-gray-300"
            :class="{ disabledRow: !transfer.is_active }"
            v-for="(transfer, i) in disbursementForm.disbursement_transfers"
          >
            <td class="border-l-[1px] border-gray-300 pl-3 text-gray-500">{{ i + 1 }}</td>
            <td class="pl-3">
              <input
                type="checkbox"
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                :checked="transfer.is_active"
                :disabled="!is_disbursement_new"
                @change="(e) => toggleInvestor(e, transfer)"
              />
            </td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-base text-gray-700">
              {{ transfer.name }}
            </td>
            <td
              class="border-r-[1px] border-gray-300 text-base text-gray-700"
              v-for="column in columns"
              v-show="!hidden_columns.includes(column.key)"
            >
              <VCurrencyField
                v-model="transfer[column.key]"
                :disabled="!transfer.is_active || !!column.disabled"
                type="number"
                :onChange="(e) => onChangeTransfer(transfer, column.key)"
                inputClass="!border-0 !shadow-none !mt-0"
                size="sm"
                v-if="column.key !== 'carried_interest'"
              />
              <div class="flex items-center pl-3" v-else>
                <div class="rounded bg-blue-50 p-1 py-0.5 text-xs text-gray-500">
                  {{ n(getCarriedInterest(transfer), 'percent') }}
                </div>
                <VCurrencyField
                  v-model="transfer[column.key]"
                  :disabled="!transfer.is_active"
                  type="number"
                  :onChange="(e) => onChangeTransfer(transfer, column.key)"
                  inputClass="!border-0 !shadow-none !mt-0"
                  size="sm"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border-b-[1px] border-gray-300">
            <td class="border-l-[1px] border-gray-300 pl-3 text-gray-500"></td>
            <td class="pl-3"></td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-xs text-gray-700">TOTALS</td>
            <td
              class="border-r-[1px] border-gray-300 bg-gray-50 text-base text-gray-700"
              v-for="column in columns"
              v-show="!hidden_columns.includes(column.key)"
            >
              <VCurrencyField
                v-model="aggregateValues[column.key]"
                type="number"
                :disabled="true"
                inputClass="!border-0 !shadow-none !mt-0"
                size="sm"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <VButton :click="submit" variant="v-blue">
      {{ capitalize(t('shared.save')) }}
    </VButton>
  </VSection>
</template>

<style>
#disbursement-prior .radio-container label:nth-child(1) {
  @apply rounded-t-lg border-[1px];
}

#disbursement-prior .radio-container label:nth-child(2) {
  @apply rounded-b-lg  border-b-[1px]  border-l-[1px]  border-r-[1px];
}

#disbursement-prior .radio-container label {
  @apply !m-0 cursor-pointer border-gray-300 px-5 py-3 hover:border-sky-500 hover:bg-sky-100;
}

#disbursement-prior .radio-container:hover label:nth-child(1) {
  @apply border-b-sky-500;
}
</style>
