<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { RouterLinkBack, VButtonInvisible, VLoadingSection } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import DisbursementForm from '../components/disbursement-form.vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisbursementStore } from '../stores/disbursement-store'

const { t } = useExtendedI18n()
const route = useRoute()
const router = useRouter()
const skeleton = ref(true)
const disbursementStore = useDisbursementStore()
const disbursement = ref(null)

const submit = async (payload) => {
  await disbursementStore.addDisbursement(payload)
  router.push({ name: 'investing.distributions' })
}

onMounted(async () => {
  await disbursementStore.fetchDisbursement(
    route.params.entity_type,
    route.params.entity_id,
    null,
    route.query.portfolio_disbursement_allocation_id,
  )
  disbursement.value = disbursementStore.items.get('undefined:null') // this is the cid if id is null
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <div class="-mt-6 mb-6 flex items-center justify-between sm:-mt-8">
      <div>
        <RouterLinkBack :to="{ name: 'investing.distributions' }">
          <VButtonInvisible>← {{ capitalize(t('shared.back')) }}</VButtonInvisible>
        </RouterLinkBack>
      </div>
    </div>
    <div class="mb-5 text-lg">New Distribution</div>
    <DisbursementForm :disbursement="disbursement" :onSave="submit" v-if="!skeleton" />
    <VLoadingSection
      class="mt-40"
      title="Preparing Your Form..."
      description="Please wait a moment while we set things up for you. Your data is being processed to ensure everything runs
      smoothly."
      v-else
    />
  </TheLayout>
</template>
